<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline" v-if="!queryParam.id">
        <a-row :gutter="48">
          <a-col :md="6" :sm="24">
            <a-form-item label="企业">
              <a-auto-complete
                v-model="queryParam.corporation"
                :data-source="dataSource"
                placeholder="请输入"
                @select="onSelect"
                @search="onSearch"
                @change="onChange"
              />
              <span>{{ tip }}</span>
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24" v-if="true">
            <a-form-item label="时间">
              <a-range-picker @change="time" format="YYYY-MM-DD" style="width: 100%" v-model="queryParam.time">
                <template slot="dateRender" slot-scope="current">
                  <div class="ant-calendar-date" :style="getCurrentStyle(current)" >
                    {{ current.date() }}
                  </div>
                </template>
              </a-range-picker>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="使用状态">
              <a-select v-model="queryParam.status" placeholder="请选择" default-value="0">
                <a-select-option value="">全部</a-select-option>
                <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.BillStatus">{{ val }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="所有人">
              <a-select v-model="queryParam.property_owner" placeholder="请选择" allow-clear>
                <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.property_owner">{{ val }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="位置">
              <a-select v-model="queryParam.location" placeholder="请选择" allow-clear>
                <!--                <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.location">{{ val }}</a-select-option>-->
                <a-select-option v-for="item in this.$Dictionaries.location" :value="Object.keys(item)[0]" :key="Object.values(item)[0]"> {{ Object.values(item)[0] }} </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="2" :xs="6">
            <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
          </a-col>
          <a-col :md="2" :xs="6">
            <a-button type="primary" icon="export" @click="handleExcel">导出</a-button>
          </a-col>
        </a-row>
      </a-form>
      <a-page-header
        v-if="queryParam.id"
        style="border: 1px solid rgb(235, 237, 240); margin-bottom: 35px"
        title="电费账单"
        @back="() => $router.go(-1)">
      </a-page-header>
    </div>

    <div class="table-operator" v-if="false">
      <a-button type="primary" icon="plus" @click="handleAdd" v-action:add>新建</a-button>
    </div>

    <s-table
      ref="table"
      size="small"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :pageNum="pageNum"
      :data="loadData"
      :scroll="{ x: 1000 }"
    >
      <template slot="footer" slot-scope="currentPageData">
        <a-row style="border-bottom: 1px solid #eeeeee; height: 40px; line-height: 40px;">
          <a-col :span="2" :xs="6" :sm="2">
            当页总计：
          </a-col>
          <a-col :span="4" :xs="10" :sm="4">
            <span>需付款 {{ totalPayment(currentPageData) | numberFormat}}</span>
          </a-col>
          <a-col :span="4" :xs="8" :sm="4">
            <span>实际付款 {{ actualPayment(currentPageData) | numberFormat}}</span>
          </a-col>
        </a-row>
      </template>
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <div slot="money_render" slot-scope="text" style="text-align: right; min-width: 100px; max-width: 160px">
        {{ (text / 100).toFixed(2) | numberFormat }}
      </div>
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handleDetail(record)">详情</a>
          <a-divider type="vertical" v-if="isCanDelete(record)"/>
          <a-popconfirm
            v-if="isCanDelete(record)"
            title="确定要删除么？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="handleDelet(record)"
            v-action:delete
          >
            <a>删除</a>
          </a-popconfirm>
          <a-divider type="vertical" />
          <a-dropdown>
            <a class="ant-dropdown-link">
              更多 <a-icon type="down" />
            </a>
            <a-menu slot="overlay">
              <a-menu-item v-if="record.status !== 6">
                <a @click="approvalInfo(record)">审批详情</a>
              </a-menu-item>
              <a-menu-item v-if="record.status === 6 || record.status === 8">
                <a @click="geneApprovalInfo(record)">生成审批</a>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </template>
      </span>
    </s-table>
    <create-form
      ref="createModal"
      :visible="visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
    <export-form
      ref="exportModal"
      :visible="exportvisible"
      :loading="confirmLoading"
      @cancel="handleExportCancel"
      @ok="handleExportOk"
    />
    <create-verify-form
      ref="createVerifyModal"
      :visible="verify_visible"
      :loading="confirmLoading"
      @cancel="handleCreateVerifyCancel"
      @ok="handleCreateVerifyOk"
    />
    <delete-verify-form
      ref="deleteVerifyModal"
      :visible="verify_delete_visible"
      :loading="confirmLoading"
      @cancel="handleDeleteVerifyCancel"
      @ok="handleDeleteVerifyOk"
    />
  </div>
</template>

<script>
import moment from 'moment'
import { STable } from '@/components'
import { Dictionaries } from '@/common/AllConstant'
import CreateForm from '../modules/CreateForm'
import ExportForm from '../modules/ExportForm'
import CreateVerifyForm from '../modules/CreateVerifyForm'
import DeleteVerifyForm from '../modules/DeleteVerifyForm'
import {
  electricity_bill_create,
  electricity_bill_delete, electricity_bill_excel_list,
  electricity_bill_list,
  electricity_bill_partial_update
} from '@/api/electricity'
import { corporation_list } from '@/api/corporation'
import { property_bill_audit } from '@/api/property_bill'
import { guarantee_bill_approval } from '@/api/rent_bill'

import { getForms, createProcessResult } from '@/api/design'

export default {
  name: 'TableList',
  components: {
    STable,
    CreateForm,
    ExportForm,
    CreateVerifyForm,
    DeleteVerifyForm
  },
  data () {
    return {
      pageNum: 1,
      dataSource: [],
      corporation_id: null,
      tip: '',
      corList: [],
      verify_visible: false,
      verify_delete_visible: false,
      loading: false,
      confirmLoading: false,
      dictionaries: Dictionaries,
      // 创建窗口控制
      visible: false,
      exportvisible: false,
      approval_again: false,
      mdl: {},
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          fixed: 'left',
          title: '序号',
          width: 50,
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: 'ID',
          dataIndex: 'id',
          width: 80,
          ellipsis: true
        },
        {
          title: '企业',
          dataIndex: 'corporation',
          width: 200,
          ellipsis: true
        },
        {
          title: '需付款(元)',
          width: 180,
          scopedSlots: { customRender: 'money_render' },
          align: 'right',
          dataIndex: 'total_payment'
        },
        {
          title: '实际付款(元)',
          dataIndex: 'actual_payment',
          width: 180,
          scopedSlots: { customRender: 'money_render' },
          align: 'right'
        },
        {
          title: '状态',
          dataIndex: 'status',
          ellipsis: true,
          width: 100,
          align: 'center',
          customRender: (text) => this.$Dictionaries.BillStatus[text]
        },
        {
          title: '所有人',
          dataIndex: 'property_owner',
          ellipsis: true,
          width: 120,
          customRender: (text) => this.$Dictionaries.electricity_meter_property_owner[text]
        },
        {
          title: '位置',
          dataIndex: 'location',
          ellipsis: true,
          width: 100,
          customRender: (text) => this.$Dictionaries.location.find((item) => !!item[text])[text]
        },
        {
          title: '创建时间',
          dataIndex: 'create_time',
          width: 180,
          ellipsis: true
        },
        {
          title: '付款时间',
          dataIndex: 'actual_payment_time',
          width: 180,
          customRender: (text) => (text === '1970-01-01 00:00:00' && '--') || text,
          ellipsis: true
        },
        {
          title: '开始时间',
          dataIndex: 'start_date',
          width: 150,
          ellipsis: true
        },
        {
          title: '结束时间',
          dataIndex: 'end_date',
          width: 150,
          ellipsis: true
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 180,
          scopedSlots: { customRender: 'action' }
        }

      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        console.log('电费参数', parameter)
        return electricity_bill_list(Object.assign(parameter, this.queryParam))
          .then(({ data }) => {
            console.log(data, '我是数据列表@')
            return data
          })
      },
      selectedRowKeys: [],
      selectedRows: [],

      // custom table alert & rowSelection
      options: {
        alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      },
      optionAlertShow: false
    }
  },
  created () {
    this.tableOption()
    console.log(this.$Dictionaries)
    this.setCurrentPage()
    const id = this.$route.params.id
    if (id) {
      this.queryParam.id = id
      console.log('有内容', id)
    } else {
      console.log('没有内容')
    }
  },
  methods: {
    setCurrentPage () {
      // const page = sessionStorage.getItem('CurrentPage')
      const page = this.$store.getters.pop
      if (page) {
        console.log(page)
        const current = page.split('#$$#')[0]
        const query = page.split('#$$#')[1]
        const path = page.split('#$$#')[2]
        sessionStorage.removeItem('CurrentPage')
        if (path === this.$route.path) {
          if (!isNaN(Number(current))) {
            this.pageNum = Number(current)
          }
          if (query) {
            this.queryParam = JSON.parse(query)
          }
        } else {
          this.queryParam = {}
          this.$store.dispatch('clear')
        }
      }
    },
    time (dates, dateStrings) {
      console.log(dateStrings, '时间')
      this.queryParam.start_date = dateStrings[0]
      this.queryParam.end_date = dateStrings[1]
    },
    getCurrentStyle (current, today) {
      const style = {}
      if (current.date() === 1) {
        style.border = '1px solid #1890ff'
        style.borderRadius = '50%'
      }
      return style
    },
    onChange (value) {
      console.log(value)
    },
    onSearch (searchText) {
      corporation_list({ name: searchText }).then((res) => {
        const reslut = res.data.entries || []
        this.corList = reslut
        if (reslut.length === 0) {
          this.tip = '未找到您输入的企业'
        } else {
          this.tip = ''
        }
        console.log('我是企业列表', reslut)
        this.dataSource = !searchText ? [] : reslut.map((item) => item.name)
        if (!(this.dataSource.length > 0)) {
          this.queryParam.corporation_id = ''
        }
      })
    },
    onSelect (value) {
      console.log(value, '我是选择的值', this.corList)
      // eslint-disable-next-line eqeqeq
      this.queryParam.corporation_id = this.corList.filter((item) => (item.name === value) && item)[0].id
      console.log('onSelect', value, this.corList.filter((item) => (item.name === value) && item))
    },
    tableOption () {
      if (!this.optionAlertShow) {
        this.options = {
          alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
          rowSelection: {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange
          }
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null
        }
        this.optionAlertShow = false
      }
    },
    handleAdd () {
      this.mdl = null
      this.visible = true
    },
    handleAddCancel () {
      this.visible = false

      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleEdit (record) {
      this.$emit('onEdit', record)
    },
    handleUpdate (record) {
      electricity_bill_partial_update({}, record.id).then((res) => {
        console.log('修改电费账单状态，由已支付转为充值完成')
      })
    },
    isCanDelete (record) {
      if (record.status === 1 || record.status >= 6) {
        return true
      } else {
        return false
      }
    },
    handleDetail (record) {
      // sessionStorage.setItem('CurrentPage', this.$refs.table.getCurrent() + '#$$#' + JSON.stringify(this.queryParam) + '#$$#' + this.$route.path)
      this.$store.dispatch('push', this.$refs.table.getCurrent() + '#$$#' + JSON.stringify(this.queryParam) + '#$$#' + this.$route.path)
      this.$router.push({ path: '/electricity_meter_record/' + record.id })
    },
    handleDelet (record) {
      electricity_bill_delete(record.id).then((res) => {
        console.log(res)
        console.log('删除成功------')

        this.$refs.table.refresh()
      })
    },
    approvalInfo (record) {
      const data = {
        data_id: record.id,
        type: 'electricity_bill'
      }
      guarantee_bill_approval(data).then((res) => {
        console.log(res.code === 1000, res.data.user_logs, res.data.user_logs.length > 0)
        if (res.code === 1000 && res.data.user_logs && res.data.user_logs.length > 0) {
          // sessionStorage.setItem('CurrentPage', this.$refs.table.getCurrent() + '#$$#' + JSON.stringify(this.queryParam) + '#$$#' + this.$route.path)
          this.$store.dispatch('push', this.$refs.table.getCurrent() + '#$$#' + JSON.stringify(this.queryParam) + '#$$#' + this.$route.path)
          this.$router.push({
            path: '/users/approval/todo/' + res.data.user_logs[0].id + '/',
            query: {
              type: res.data.user_logs[0].business_type
            }
          })
        }
      })
    },
    geneApprovalInfo (record) {
      this.approval_again = record.status === 8
      this.createContractApproval([record.id], 'electricity_bill')
    },
    createContractApproval (ids, type) {
      getForms({ is_stop: 0, template_type: type }).then(res => {
        console.log(res)
        this.shenpi_list = res.data.entries
        if (this.shenpi_list && this.shenpi_list.length > 0) {
          for (let i = 0, len = this.shenpi_list.length; i < len; i++) {
            const item = this.shenpi_list[i]
            const settings = JSON.parse(item.settings)
            if (settings) {
              const notify = settings.notify
              if (notify) {
                const types = notify.types
                if (types) {
                  if (types.type === type) {
                    this.createProcessNotice(item.id, ids, type)
                    break
                  }
                }
              }
            }
          }
        } else {
          // this.$message.error('先创建合同审批模板')
          this.createProcessNotice(-1, ids, type)
        }
      })
    },
    createProcessNotice (template_id, ids, type) {
      const data = {
        'data_ids': ids,
        'template_id': template_id,
        'template_type': type,
        'approval_again': this.approval_again
      }
      createProcessResult(data).then(res => {
        console.log(res)
        if (res.code === 1000) {
          console.log('创建成功')
          this.$refs.table.refresh()
        }
      })
    },
    handleOk () {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values)
          // 新增
          electricity_bill_create(values).then(res => {
              this.visible = false
              this.confirmLoading = false
              // 重置表单数据
              form.resetFields()
              // 刷新表格
              this.$refs.table.refresh()
            console.log('删除成功------')
          }).finally(() => {
            this.confirmLoading = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },

    resetSearchForm () {
      this.queryParam = {
        date: moment(new Date())
      }
    },
    handleExcel () {
      console.log('导出')
      // this.exportvisible = true
      this.handleExportOk()
    },
    handleExportCancel () {
      this.exportvisible = false
      const form = this.$refs.exportModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleExportOk () {
      console.log('ok')
      const form = this.$refs.exportModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values)
          // 新增
          electricity_bill_excel_list(this.queryParam).then((res) => {
            const link = document.createElement('a')
            const blob = new Blob([res], { type: 'application/octet-stream' })
            console.log(blob)
            link.style.display = 'none'
            link.href = URL.createObjectURL(blob)
            link.setAttribute('download', `电费账单.xlsx`)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          }).finally(() => {
            this.confirmLoading = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleCreateVerify (record) {
      this.verify_visible = true
      this.mdl = record
      console.log(record)
    },
    handleCreateVerifyCancel () {
      this.verify_visible = false
    },
    handleCreateVerifyOk (data) {
      console.log(data)
      this.verify_visible = false
      // category  1: '物业',2: '租金',3: '水费',4: '电费',
      // flag 1-创建审核 2-删除审核
      const request = {
        'bill_id': this.mdl.id,
        'category': 4,
        'status': 0,
        'flag': 1
      }
      if (data === 'yes') {
        request.status = 1
      } else if (data === 'no') {
        request.status = 3
      }

      property_bill_audit(request).then(res => {
        console.log(res)
        if (res && res.code === 1000) {
          console.log('成功')
          this.$refs.table.refresh()
        }
      })
    },
    handleDeleteVerify (record) {
      this.verify_delete_visible = true
      this.mdl = record
      console.log(record)
    },
    handleDeleteVerifyCancel () {
      this.verify_delete_visible = false
    },
    handleDeleteVerifyOk (data) {
      console.log(data)
      this.verify_delete_visible = false
      // category  1: '物业',2: '租金',3: '水费',4: '电费',
      // flag 1-创建审核 2-删除审核
      const request = {
        'bill_id': this.mdl.id,
        'category': 4,
        'status': 0,
        'flag': 2
      }
      if (data === 'yes') {
        request.status = 3
      } else if (data === 'no') {
        request.status = 1
      }

      property_bill_audit(request).then(res => {
        console.log(res)
        if (res && res.code === 1000) {
          console.log('成功')
          this.$refs.table.refresh()
        }
      })
    },
    totalPayment (currentPageData) {
      return (currentPageData.reduce((prev, curr) => prev + curr.total_payment, 0) / 100).toFixed(2)
    },
    actualPayment (currentPageData) {
      return (currentPageData.reduce((prev, curr) => prev + curr.actual_payment, 0) / 100).toFixed(2)
    }
  }
}
</script>
